import * as angular from "angular";
import * as _ from "underscore";
import { IContext } from "../Contexts/Context";
import { SearchSubscription } from "../SearchSubscriptions/searchSubscription";
import { ContextType } from "../Contexts/ContextType";
import { PortalDataHttpService } from "../Services/DataService/PortalDataHttpService";
import "./allowPrivileges.directive";
import { UserProfileDto, UserViewModel } from "@rhinestone/portal-web-api";

// TODO: refactor use of this service so its either initialized before rest of application, or use promises exclusively
// to ensure callers don't end up in race condition bugs
export interface ICurrentUserService {
  /**
   * OBSOLETE
   *
   * use getUser() instead
   *
   * this property is not guaranteed to be set correctly, and using it results in race condition bugs
   *
   * (alternatively refactor this service to guaranteed it is initialized before rest of application)
   */
  user: UserViewModel;
  /**
   * OBSOLETE
   *
   * use isAuthenticatedAsync() instead
   *
   * this property is not guaranteed to be set correctly, and using it results in race condition bugs
   *
   * (alternatively refactor this service to guaranteed it is initialized before rest of application)
   */
  isAuthenticated: boolean;
  isAuthenticatedAsync(): Promise<boolean>;
  deleteSearchSubscription(subscriptionId: string): Promise<{}>;
  getOrganizationIdentifier(): Promise<string>;
  getUser(): Promise<UserViewModel>;
  getUserProfile(): Promise<UserProfileDto>;
  getContext(): Promise<IContext>;
  getSearchSubscriptions(): Promise<SearchSubscription[]>;
  updateSearchSubscription(subscription: SearchSubscription): Promise<{}>;
  getVerifiedEmails(): Promise<string[]>;
  hasPrivilege(...privileges: string[]): boolean;
}

export class UserEvents {
  public static UserAuthenticated = "user-authenticated";
}

/**
 *
 */
export class CurrentUserService implements ICurrentUserService {
  public user: UserViewModel;
  public userProfile: UserProfileDto;
  private isInitialized = false;
  private isProfileInitialized = false;
  private userDeferrer = this.$q.defer<UserViewModel>();
  private userProfileDeferrer = this.$q.defer<UserProfileDto>();

  public isAuthenticated = false;

  public async getOrganizationIdentifier(): Promise<string> {
    return (await this.getUser())?.organizationIdentifier?.toLowerCase();
  }

  public static $inject = [
    "$q",
    "$rootScope",
    "$window",
    "$translate",
    "portalDataService",
    "$location"
  ];

  constructor(
    private $q: angular.IQService,
    private $rootScope: angular.IRootScopeService,
    private $window: angular.IWindowService,
    private $translate: angular.translate.ITranslateService,
    private portalDataService: PortalDataHttpService,
    private $location: angular.ILocationService
  ) {}

  public async isAuthenticatedAsync() {
    try {
      await this.getUser();
    } finally {
      return this.isAuthenticated;
    }
  }

  public logout() {
    this.$window.location.href = `/Account/SignIn?returnUrl=${encodeURIComponent(
      this.$location.url()
    )}`;
  }

  public async getUser(): Promise<UserViewModel> {
    if (this.isInitialized) {
      return this.userDeferrer.promise;
    }
    this.isInitialized = true;

    try {
      this.user = await this.portalDataService.get<UserViewModel>("User");
      this.isAuthenticated = this.user !== undefined;
      this.userDeferrer.resolve(this.user);
    } catch {
      this.user = undefined;
      this.isAuthenticated = false;
      this.userDeferrer.reject();
    }

    if (this.isAuthenticated) {
      this.$rootScope.$broadcast(UserEvents.UserAuthenticated);
    }

    return this.userDeferrer.promise;
  }

  public async getUserProfile(): Promise<UserProfileDto> {
    if (this.isProfileInitialized) {
      return this.userProfileDeferrer.promise;
    }
    this.isProfileInitialized = true;

    try {
      this.userProfile = await this.portalDataService.get<UserProfileDto>(
        "userprofile"
      );
      this.userProfileDeferrer.resolve(this.userProfile);
    } catch {
      this.userProfile = undefined;
      this.userProfileDeferrer.reject();
    }
    return this.userProfileDeferrer.promise;
  }

  public async getContext(): Promise<IContext> {
    const user = await this.getUser();
    const translation = await this.$translate("assets.my_comments");

    return {
      ContextId: user.id,
      Label: translation,
      HighlightClass: "annotation-highlight",
      PdfHighlightColor: "yellow",
      Disabled: false,
      ToolTip: "",
      ContextType: ContextType.User,
      ContextTitle: ""
    };
  }

  // TODO: move to searchSubscriptionService
  public async getSearchSubscriptions(): Promise<SearchSubscription[]> {
    return await this.portalDataService.get<SearchSubscription[]>(
      "User/SearchSubscriptions"
    );
  }

  // TODO: move to searchSubscriptionService
  public async updateSearchSubscription(
    subscription: SearchSubscription
  ): Promise<{}> {
    return await this.portalDataService.put<{}>(
      `User/SearchSubscriptions(${subscription.subscriptionId})`,
      subscription
    );
  }

  // TODO: move to searchSubscriptionService
  public async deleteSearchSubscription(subscriptionId: string): Promise<{}> {
    return await this.portalDataService.delete(
      `CurrentUser/SearchSubscriptions(${subscriptionId})`
    );
  }

  public async getVerifiedEmails(): Promise<string[]> {
    return await this.portalDataService.get<string[]>(
      `CurrentUser/VerifiedEmailAddresses`
    );
  }

  public hasPrivilege(...privileges: string[]): boolean {
    return (
      this.user && _.intersection(this.user.privileges, privileges).length > 0
    );
  }
}

angular.module("PortalApp").service("currentUserService", CurrentUserService);
