import { SpaConfigDto } from "@rhinestone/portal-web-api";

export function initGoogleTagManager(rhinestoneConfig: SpaConfigDto) {
  if (!rhinestoneConfig.googleTagManagerId) return;
  setDefaultLanguageOnGlobalObject(rhinestoneConfig.defaultLanguage);
  injectDefaultGtmScript(rhinestoneConfig.googleTagManagerId);
}

/**
 * Sets the default language code on the JS global object.
 * A variable with the same name should be defined in GTM,
 * and used for setting the language in the Cookiebot tag.
 * We need to do this before initializing GTM, as setting
 * data layer variables is handled in order by GTM, thus
 * we can't guarantee it's set when Cookiebot tag fires.
 * @param language 2-letter ISO standard language code
 */
function setDefaultLanguageOnGlobalObject(language: string) {
  const w = window as any;
  w["defaultLanguage"] = language;
}

/**
 * Ported from google tag manager code samples
 * https://support.google.com/tagmanager/answer/14847097
 */
function injectDefaultGtmScript(googleTagManagerId: string) {
  const w = window as any;
  const d = document;
  const l = "dataLayer";
  const p = "#gtm-anchor";

  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  const f = d.querySelector(p),
    j = d.createElement("script"),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "/gtm.js?id=" + googleTagManagerId + dl; // This path is matched and proxied to google server-side

  if (f === undefined)
    throw Error(
      "Couldn't find place to insert google tag manager script. " +
        `Expecting a script tag with id '${p}' on page.` +
        "Please make sure to add this or change this code"
    );
  f.parentNode.insertBefore(j, f);
}
