import * as angular from "angular";
import { SpaConfigDto } from "@rhinestone/portal-web-api";
import { initGoogleTagManager } from "./google-tag-manager";

export async function initializeApplication(rhinestoneConfig: SpaConfigDto) {
  document.title = rhinestoneConfig.title;
  insertPortalCustomCssStylesheet(rhinestoneConfig);
  initGoogleTagManager(rhinestoneConfig);
  angular.module("PortalApp").constant("rhinestoneConfig", rhinestoneConfig);
  angular.bootstrap(document, ["PortalApp"]);
}

export function insertPortalCustomCssStylesheet(
  rhinestoneConfig: SpaConfigDto
) {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = `/api/Portals(${rhinestoneConfig.identifier})/Css?version=${rhinestoneConfig.lastModifiedTag}`;
  document.head.appendChild(link);
}

/**
 *
 * @param error the error object from fetch catch block
 */
export function bootStrapErrorHandler(error: Error) {
  const errorBlock = document.createElement("div");
  errorBlock.textContent = `Unable to get configuration. Can't start application. Contact support.`;

  if (process.env.NODE_ENV === "development") {
    const devMessage = document.createElement("div");
    devMessage.setAttribute("style", "margin-top: 20px");
    devMessage.textContent =
      "Dear developer: Unable to get bootstrap data from endpoint '/bootstrap', or subsequent initialization of SPA failed. " +
      "Please check console, network traffic and app.run.ts, and investigate why endpoint doesn't return expected data. See exception below:";
    const exceptionBlock = document.createElement("div");
    exceptionBlock.setAttribute("style", "margin-top: 20px");
    exceptionBlock.textContent = error.message;
    devMessage.appendChild(exceptionBlock);
    errorBlock.appendChild(devMessage);
  }

  document.body.insertBefore(errorBlock, document.body.firstElementChild);
}
